import './search.scss';
import AjaxLoad from '../../utilities/js/ajax-load';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

class Search {
    constructor (element) {
        this.$search = element;
        this.$searchInput = this.$search.querySelector('[data-search="input"]');
        this.$searchButton = this.$search.querySelector('[data-search="button"]');
        this.$closeButton = this.$search.querySelector('[data-search="close"]');
        this.$searchResults = this.$search.querySelector('[data-search="results"]');
        this.$searchInfos = this.$search.querySelector('.siteheader__search-placeholder');
        this.$form = null;

        this.searchOpened = false;
        this.searchOpen = false;
    }

    initialize () {
        if (this.$search) {
            this.setEvents();
        }
    }

    setEvents () {
        if (this.$searchButton) {
            this.$searchButton.addEventListener('click', () => {
                this.openSearchBar();
            });
        }

        if (this.$closeButton) {
            this.$closeButton.addEventListener('click', () => {
                this.closeSearchBar();
            });
        }

        if (this.$searchInput) {
            this.$searchInput.addEventListener('keydown', (e) => {
                setTimeout(() => {
                    const inputValue = this.$searchInput.value;
                    const valueLength = inputValue.length + 1;
                    if (valueLength > 3) {
                        this.$searchResults.classList.add('is--visible');
                        const searchValue = this.$searchInput.value;
                        const formAction = this.$form.action + '?term=' + searchValue;
                        const formMethod = this.$form.method;
                        const formData = new FormData(this.$form);

                        this.formResult = new AjaxLoad(formAction, {
                            requestMethod: formMethod,
                            requestData: formData,
                            onSuccess: (resultText) => {
                                this.$searchResults.innerHTML = resultText;
                            }
                        });
                    } else {
                        this.$searchResults.classList.remove('is--visible');
                    }
                }, 200);

                if (e.keyCode === 13) {
                    e.preventDefault();
                }
            });

            this.$searchInput.addEventListener('click', () => {
                if (!this.searchOpen) {
                    this.openSearchBar();
                }
            });
        }

        document.body.addEventListener('click', (e) => {
            if (this.searchOpen) {
                const $clickedElement = e.target;
                let dataOverlay = $clickedElement.getAttribute('data-search');
                if (!dataOverlay) {
                    const $parentElement = $clickedElement.closest('[data-search]');
                    if ($parentElement) {
                        dataOverlay = true;
                    }
                }

                if (!dataOverlay) {
                    this.closeSearchBar();
                }
            }
        });
    }

    openSearchBar () {
        this.$search.classList.add('is--opened');
        const placeholder = this.$searchInfos.getAttribute('data-placeholder');
        this.$searchInput.placeholder = placeholder;
        if (!this.searchOpened) {
            const action = this.$searchInfos.getAttribute('data-action');
            const form = document.createElement('form');
            form.action = action;
            form.method = 'post';
            this.$search.insertBefore(form, this.$searchInput);
            form.appendChild(this.$searchInput);
            this.$form = form;
        }
        disableBodyScroll(this.$searchResults);

        this.$searchInput.focus();
        this.searchOpened = true;
        this.searchOpen = true;
    }

    closeSearchBar () {
        this.$search.classList.remove('is--opened');
        this.$searchResults.classList.remove('is--visible');
        const placeholder = this.$searchInfos.getAttribute('data-placeholder-closed');
        this.$searchInput.placeholder = placeholder;
        enableBodyScroll(this.$searchResults);
        this.searchOpen = false;
        this.$searchInput.value = '';
    }
}

export { Search };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $searchs = $context.querySelectorAll('[data-search="root"]');
        if ($searchs.length > 0) {
            for (let i = 0; i < $searchs.length; i++) {
                const searchAPI = new Search($searchs[i]);
                searchAPI.initialize();
            }
        }
    }
});
